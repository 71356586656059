import React from "react";
import "../../styling/scss/main/study-code.scss";

export class ContactUs extends React.Component<{}, {}> {
    render() {
        return (
            <div></div>
        )
    }
}

export default ContactUs;