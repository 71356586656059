import * as React from 'react';
import '../../styling/scss/header/navbar.scss';

export interface NavLinkProps {
    navLinks: Array<any>,
}

export class NavLink extends React.Component<NavLinkProps, {}> {
    render() {
        return (
            <React.Fragment>
                <label className="nav-toggle" htmlFor="checkbox">&#9776; Menu</label>
                <input type="checkbox" id="checkbox" />
                <ul id="navItems" className="nav-items" style={{}}>
                    {this.props.navLinks.map((link: any) => <li key={link} className="nav-item">{link}</li>)}
                </ul>
            </React.Fragment>
        );
    }
}

export class LangToggle extends React.Component<{}, {}> {
    render() {
        return (
            <button className="btn">NL</button>
        );
    }
}

// Nav-bar top contains the logo and lang toggle.
export interface NavbarTopProps {
    showLangToggle: boolean
}

export class NavbarTop extends React.Component<NavbarTopProps, {}> {
    render() {
        return (
            <div className="navbar-top">
                <a href="/">
                    <div className="nav-brand">
                        <h3>PIFinformatie</h3>
                    </div>
                </a>
                {
                    this.props.showLangToggle ?
                        <span>
                            <LangToggle />
                        </span> :
                        <span style={{ visibility: "hidden" }}>
                            <LangToggle />
                        </span>
                }
            </div>
        );
    }
}

// Navbar bottom contains links to the different pages. This compontent is optional and set with the showNavbarBottom propertie in navbar.
export interface NavbarBottomProps {
    NavLinks: Array<any>,
}

export class NavbarBottom extends React.Component<NavbarBottomProps, {}> {
    render() {
        return (
            <div className="navbar-bottom">
                <NavLink navLinks={this.props.NavLinks} />
            </div>
        );
    }
}
