import React, { ComponentClass } from "react";
import { Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTextHeight, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Navbar from './components/header/navbar';
import './styling/scss/dropdown.scss';
import './styling/scss/study.scss';
import './styling/scss/study-tools.scss';
import LanguageDropdown from './components/study/language-dropdown';
import TableOfContents from './components/study/table-of-contents';
import { ColumnSection } from './components/main/column-sections';
import StudyChapter from './components/study/study-chapter';
import { withRouter } from "react-router";
import { ReactRouterProperties } from './components/study/table-of-contents-types';
import TextToSpeech from './components/study/text-to-speech';

export interface ParamsInterface {
  studyToken: string,
  chapter: string,
  heading: string
}

export interface StudyInterface {
  code: string | undefined,
  medicine: string | undefined,
  name: string | undefined,
  pif_html: HTMLElement | null,
  pif_id: string | undefined,
  token: string | undefined,
  url: string | undefined
}

export interface StudyProps extends ReactRouterProperties {
}

export interface StudyState {
  tableOfContents: any,
  chapterText: any,
  pif: StudyInterface | undefined,
  heading: string,
  chapter: string,
  studyToken: string,
  numberOfChapters: Number
}

class Study extends React.Component<StudyProps, StudyState> {

  constructor(props: StudyProps) {
    super(props);
    this.state = {
      tableOfContents: null,
      chapterText: null,
      pif: undefined,
      heading: this.props.match?.params.heading as string,
      chapter: this.props.match?.params.chapter as string,
      studyToken: this.props.match?.params.studyToken as string,
      numberOfChapters: 0
    }
  }

  componentDidMount() {
    window.localStorage.setItem("large-size", "false");

    fetch("/api/pif/" + this.state.studyToken + "/" + this.state.chapter + "/", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((result: Response) => result.json())
      .then(data => this.setState({ pif: data }))
      .then(() => this.setState({ chapterText: <StudyChapter pifHTML={this.state.pif?.pif_html} heading={this.state.heading} large={false} /> }))
      // Wait for the text to load before we try to set the table of contents.
      .then(() => this.setState({ tableOfContents: <TableOfContents></TableOfContents> }))
      .then(() => this.setState({ numberOfChapters: this.getNumberOfChapters() }))
      .then(() => this.setNextChapterButtons());
  }

  toggleSize() {
    window.localStorage.setItem("large-size", window.localStorage.getItem("large-size") === "true" ? "false" : "true");

    this.setState({
      chapterText: <StudyChapter pifHTML={this.state.pif?.pif_html} heading={this.state.heading} large={window.localStorage.getItem("large-size") === "true"} />
    });
  }

  getNumberOfChapters(): Number {
    return document.getElementById("content")?.getElementsByClassName("study")[0]?.children.length ?? 0;
  }

  setNextChapterButtons() {
    var a = document.getElementById("go-to-next-chapter");
    var span = document.getElementById("go-to-next-chapter-text");

    // If it's the last chapter.
    if ((parseInt(this.state.chapter) + 1) > this.state.numberOfChapters) {
      (document.getElementsByClassName("next-button-container")[0] as HTMLDivElement)!.style.display = "none";
    }

    // If there is a next heading.
    if (this.getNextHeadingID(this.state.heading) && false) {
      //a?.setAttribute("href", "/study/" + this.state.studyToken + "/" + this.state.chapter + "/" + this.getNextHeadingID(this.state.heading) + "/");
      //if (span) span.textContent = "Ga verder naar: " + this.getChapterNameByID(this.getNextHeadingID(this.state.heading));
    // If there is no next heading.
    } else {
      let chapterID = this.getChapterIDByNumber(parseInt(this.state.chapter) + 1);

      a?.setAttribute("href", "/study/" + this.state.studyToken + "/" + (parseInt(this.state.chapter) + 1) + "/" + chapterID + "/");

      if (span) span.textContent = "Ga verder naar: " + this.getChapterNameByNumber(parseInt(this.state.chapter) + 1);
    }
  }

  getChapterIDByNumber(chapterNumber: Number): string | undefined {
    return document.getElementById("chapter-" + chapterNumber.toString())?.firstElementChild?.id;
  }

  getChapterNameByNumber(chapterNumber: Number): string | undefined {
    return document.getElementById("chapter-" + chapterNumber.toString())?.firstElementChild?.textContent?.toString();
  }

  getChapterNameByID(chapterID: string): string {
    return document.getElementById(chapterID)?.innerText ?? "";
  }

  getNextHeadingID(chapterID: string): string {
    var currentElement = document.getElementById(chapterID)?.nextElementSibling;

    while (currentElement != null && currentElement?.tagName != "H3") {
      currentElement = currentElement?.nextElementSibling as HTMLElement;
    }

    return currentElement != null ? currentElement.id : "";
  }

  getFirstHeadingOfChapter(chapterID: string) {
    let currentElement = document.getElementById(chapterID);

    while (currentElement != null && currentElement?.tagName != "H3") {
      currentElement = currentElement?.nextElementSibling as HTMLElement;
    }

    return currentElement?.id;
  }

  render() {
    return (
      <div id="study">
        <div className="navbar-mobile">
          <Navbar showNavbarBottom={false} showLangToggle={false} navLinks={[]} showBackground={true} />

          <div className="study-navigation-wrapper">
            <div className="study-navigation container">

              <div className="mobile-toc-toggle-container">
                <div className="mobile-toc-toggle hidden">
                  <FontAwesomeIcon className="toc-icon" icon={faBars} />
                  <FontAwesomeIcon className="close-icon" icon={faTimes} />
                  <span>Menu</span>
                </div>
              </div>
              {/* Study-tools are the 3 features for the study. Text increase, Text to speech and the language selection which needs implementation */}
              <div className="study-tools">
                <div className="study-tool">
                  <TextToSpeech chapter={this.state.chapter} />
                </div>
                <div className="study-tool">
                  <FontAwesomeIcon icon={faTextHeight} onClick={() => this.toggleSize()} title="Tekstgrootte aanpassen" />
                </div>

                <div className="study-tool">
                  <LanguageDropdown studyToken={this.state.studyToken} study={this} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColumnSection class="column-2">
          <div className="item-1 toc-unfixed">
            {this.state.tableOfContents}
          </div>
          <div className="item-2">
            <div id="content">

            </div>
            <div className="next-button-container">
              {
                <a id="go-to-next-chapter">
                  <div className="next-button">
                    <span><FontAwesomeIcon icon={faArrowRight} className="next-button-icon"></FontAwesomeIcon></span>
                    {
                      <span id="go-to-next-chapter-text"></span>
                    }
                  </div>
                </a>
              }
            </div>
          </div>
        </ColumnSection>
        <Route path="/study/:studyToken/:chapter/" exact>
          {this.state.chapterText}
        </Route>
        <Route path="/study/:studyToken/:chapter/:heading/" exact>
          {this.state.chapterText}
        </Route>
      </div>
    );
  }
}

export default withRouter(Study as ComponentClass<any, any>);
