import React, { Component } from "react";
import Navbar from "./components/header/navbar";
import HeroSection from "./components/header/hero-section";
import { Footer } from "./components/footer/footer";

import heroBG from "../src/static/proefpersoneninformatie_temphero_bg.png";
import hero from "../src/static/proefpersoneninformatie_temphero.png";

export class Home extends Component {
  render() {
    return (
      <React.StrictMode>
        <React.Fragment>
          <Navbar
            showLangToggle={false}
            showNavbarBottom={false}
            navLinks={[]}
            showBackground={false}
          />
          <HeroSection
            title="Mensen zijn onmisbaar in geneeskundig onderzoek."
            urlbg={heroBG}
            url={hero}
            content={[]}
          />
          <Footer />
        </React.Fragment>
      </React.StrictMode>
    );
  }
}

export default Home;
