import React, { ComponentClass } from "react";
import { withRouter } from "react-router";
import "../../styling/scss/table-of-contents.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { Chapter, Heading, Subheading, ReactRouterProperties } from './table-of-contents-types';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export interface TableOfContentsState {
    tableOfContents: React.RefObject<HTMLDivElement>;
    pifHTML: string;
    chapters: Chapter[];
}

interface Location {
    hash: string
    pathname: string,
    search: string,
    state: Object
}

export interface TableOfContentsProps extends ReactRouterProperties {

}

export class TableOfContents extends React.Component<TableOfContentsProps, TableOfContentsState> {
    mobileTocShown: boolean = false;
    onSmallDevice = window.matchMedia("(max-width: 768px)").matches;
    eventAssigned = false;
    tableOfContentsWidth = 0;
    tableOfContentsClickEvent = (e: any) => this.onSmallDeviceWindowClick(e);

    constructor(props: TableOfContentsProps) {
        super(props);
        this.state = {
            tableOfContents: React.createRef(),
            pifHTML: "",
            chapters: []
        };
    }

    componentDidMount() {
        this.tableOfContentsWidth = (document.getElementsByClassName("item-1")[0] as HTMLDivElement).offsetWidth;

        fetch("/api/pif/" + this.props.match?.params.studyToken + "/", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((result: Response) => result.json())
            .then(data => {
                this.setState({ pifHTML: data["pif_html"] });
            })
            .then(() => this.getTitles());

        if (!this.onSmallDevice) {
            window.onresize = () => {
                // Measure the table of contents width again.
                this.tableOfContentsWidth = (document.getElementsByClassName("item-1")[0] as HTMLDivElement).offsetWidth;

                this.onWindowResize();
            }
        }
        
        (document.getElementsByClassName("mobile-toc-toggle")[0] as HTMLDivElement).onclick = () => this.onMobileToggleClick();

        document.getElementById("table-of-contents")?.setAttribute("data-mobile", this.onSmallDevice ? "true" : "false");

        if (this.onSmallDevice && !this.eventAssigned) {
            window.addEventListener('click', this.tableOfContentsClickEvent);

            this.eventAssigned = true;
        } else if (!this.onSmallDevice) {
            window.removeEventListener('click', this.tableOfContentsClickEvent);

            this.eventAssigned = false;
        }

        // Execute when all images are loaded.
        Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
            // Get the subheading ID from the URl.
            let subheadingId = window.location.hash.substr(1);

            // If the subheading ID is present.
            if (subheadingId) {
                // Scroll to the subheading.
                this.scrollToSubheading(subheadingId);
            }
        });
    }

    onSmallDeviceWindowClick(e: MouseEvent) {
        // If clicked outside of the table of contents.
        if (!(document.getElementsByClassName("item-1")[0] as HTMLDivElement).contains(e.target as Node) &&
            !(document.getElementsByClassName("mobile-toc-toggle")[0] as HTMLDivElement).contains(e.target as Node)) {
            this.hideMobileToc();
        }
    }

    onWindowResize() {
        // Measure screen again.
        this.onSmallDevice = window.matchMedia("(max-width: 768px)").matches;

        if (this.onSmallDevice) {
            this.hideMobileToc();

            document.getElementById("table-of-contents")?.setAttribute("data-mobile", "true");
        } else {
            this.showMobileToc();

            document.getElementById("table-of-contents")?.setAttribute("data-mobile", "false");
        }

        var container = document.getElementsByClassName("item-1")[0] as HTMLDivElement;
        container.classList.remove("toc-fixed");

        document.getElementById("table-of-contents")?.setAttribute("data-mobile", this.onSmallDevice ? "true" : "false");

        if (this.onSmallDevice && !this.eventAssigned) {
            window.addEventListener('click', this.tableOfContentsClickEvent);

            this.eventAssigned = true;
        } else if (!this.onSmallDevice) {
            window.removeEventListener('click', this.tableOfContentsClickEvent);

            this.eventAssigned = false;
        }
    }

    onMobileToggleClick() {
        if (this.mobileTocShown) {
            this.hideMobileToc();
        } else {
            this.showMobileToc();
        }
    }

    getTitles() {
        var wrapper = document.createElement("div");
        wrapper.innerHTML = this.state.pifHTML;

        var section = wrapper.getElementsByTagName('section')[0];
        let chapterArray: Chapter[] = [];

        for (let i = 0; i < section.children.length; i++) {
            var chapterElement = section.children[i];
            let currentChapter: Chapter = { value: null, headings: [] };
            let currentHeading: Heading = { value: null, subheadings: [] };

            for (let x = 0; x < chapterElement.children.length; x++) {
                var element = chapterElement.children[x];
                var id = element.getAttribute("id");

                if (element.classList.contains("style-chapter")) {
                    currentChapter = { value: id, headings: [] };
                    chapterArray.push(currentChapter);
                }
                else if (element.classList.contains("style-heading")) {
                    currentHeading = { value: id, subheadings: [] };
                    currentChapter.headings.push(currentHeading);
                }
                else if (element.classList.contains("style-subheading")) {
                    currentHeading.subheadings.push({ value: id });
                }
            }
        }

        this.setState({ chapters: chapterArray });
    }

    showMobileToc() {
        var mobileTocToggle = (document.getElementsByClassName("mobile-toc-toggle")[0] as HTMLDivElement);
        var mobileToc = document.getElementsByClassName("item-1")[0] as HTMLDivElement;
        var offsetHeight = (document.getElementsByClassName("navbar-mobile")[0] as HTMLDivElement).offsetHeight;

        mobileTocToggle.classList.remove("hidden");
        mobileTocToggle.classList.add("shown");

        mobileToc.style.display = "block";

        mobileToc.style.top = String(offsetHeight + 10) + "px";

        this.mobileTocShown = true;
    }

    hideMobileToc() {
        var mobileTocToggle = (document.getElementsByClassName("mobile-toc-toggle")[0] as HTMLDivElement);
        var mobileToc = document.getElementsByClassName("item-1")[0] as HTMLDivElement;

        mobileTocToggle.classList.remove("shown");
        mobileTocToggle.classList.add("hidden");

        mobileToc.style.display = "none";

        this.mobileTocShown = false;
    }

    getChapterNumber(name: string): string | undefined {
        return document.getElementById(name)?.parentElement?.getAttribute("id")?.replace("chapter-", "");
    }

    getFirstHeadingOfChapter(chapterID: string) {
        let currentElement = document.getElementById(chapterID);

        while (currentElement != null && currentElement?.tagName != "H3") {
            currentElement = currentElement?.nextElementSibling as HTMLElement;
        }

        return currentElement?.id;
    }

    onChapterClick(chapterID: string) {
        let url = "/study/" + this.props.match?.params.studyToken + "/" + this.getChapterNumber(chapterID) + "/" + chapterID + "/";

        window.location.href = url;
    }

    onHeadingClick = (id: any) => {
        if (this.onSmallDevice) {
            this.hideMobileToc();
        }

        window.location.href = '/study/' + this.props.match?.params.studyToken + '/' + this.props.match?.params.chapter + '/' + id + '/';
    }

    onSubheadingClick = (subheadingId: any, headingId: any) => {
        if (this.onSmallDevice) {
            this.hideMobileToc();
        }

        if (headingId == this.props.match?.params.heading) {
            this.scrollToSubheading(subheadingId);
        } else {
            window.location.href = '/study/' + this.props.match?.params.studyToken + '/' + this.props.match?.params.chapter + '/' + headingId + '/#' + subheadingId;
        }
    }

    scrollToSubheading(subheadingId: any) {
        var offset = document.getElementById(subheadingId)?.offsetTop ?? 0;
        var offsetHeight = (document.getElementsByClassName("navbar-mobile")[0] as HTMLDivElement).offsetHeight;

        window.scrollTo({ top: this.onSmallDevice ? offset - offsetHeight : offset });
    }

    render() {
        return (
            <div ref={this.state.tableOfContents} id="table-of-contents-wrapper">
                <div id="table-of-contents">
                    {
                        this.state.chapters.map((chapter: Chapter) => {
                            let l: Location = {
                                hash: "",
                                pathname: "/study/" + this.props.match?.params.studyToken + "/" + this.getChapterNumber(chapter.value as string) + '/',
                                search: "",
                                state: { fromDashboard: true }
                            };

                            let isCurrentChapter: boolean = this.props.match?.params.chapter === this.getChapterNumber(chapter.value as string);
                            return (
                                <span>
                                    <span className={"toc-item toc-item-chapter" + (isCurrentChapter ? " toc-item-current" : "")}>
                                        <a onClick={() => this.onChapterClick(chapter.value!)}><FontAwesomeIcon icon={isCurrentChapter ? faDotCircle : faArrowRight} className="toc-item-icon" /></a>
                                        <a onClick={() => this.onChapterClick(chapter.value!)}>{document.getElementById(chapter.value as string)?.innerText}</a>
                                    </span>
                                    <ul className="headings-list">
                                        {
                                            chapter.headings.map((heading: Heading) => {
                                                let isCurrentHeading = heading["value"] === this.props.match?.params.heading;
                                                return (
                                                    isCurrentChapter ?
                                                        <li className={"toc-item toc-item-heading " + (isCurrentHeading ? "toc-item-heading-current" : "")}>
                                                            <a onClick={() => this.onHeadingClick(heading["value"])}>
                                                                {document.getElementById(heading.value as string)?.innerText}
                                                            </a>
                                                            <ul className="subheadings-list">
                                                                {
                                                                    heading.subheadings.map((subheading: Subheading) => {
                                                                        return (
                                                                            <li className={"toc-item toc-item-subheading"}>
                                                                                <a onClick={() => this.onSubheadingClick(subheading["value"], heading["value"])}>
                                                                                    {document.getElementById(subheading.value as string)?.innerText}
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </li>
                                                        :
                                                        <span></span>
                                                )
                                            })
                                        }
                                    </ul>
                                </span>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(TableOfContents as ComponentClass<any, any>);
