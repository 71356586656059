import * as React from 'react';
import { ColumnSection } from '../main/column-sections';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons';

export interface FooterProps {

}

export interface FooterState {
	form: React.RefObject<HTMLFormElement>;
	nameInput: React.RefObject<HTMLInputElement>;
	emailInput: React.RefObject<HTMLInputElement>;
	messageInput: React.RefObject<HTMLTextAreaElement>;
	honeypotInput: React.RefObject<HTMLInputElement>;
}

export class Footer extends React.Component<FooterProps, FooterState> {
	constructor(props: {}) {
		super(props);
		this.state = {
			form: React.createRef(),
			nameInput: React.createRef(),
			emailInput: React.createRef(),
			messageInput: React.createRef(),
			honeypotInput: React.createRef()
		}

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		const script = document.createElement("script");

		script.src = "https://kit.fontawesome.com/f44d783c58.js";
		script.crossOrigin = "anonymous"

		document.body.appendChild(script);
	}

	render() {
		return (
			<footer className="footer">
				<ColumnSection class="column-3">
					<article>
						<div className="contact-form">
							<h3>Neem contact op</h3>
							<form method="POST" action="/api/message/" onSubmit={this.handleSubmit} ref={this.state.form}>
								<label>Naam:</label><br />
								<input type="text" ref={this.state.nameInput} /><br />

								<label>E-mailadres:</label><br />
								<input type="email" ref={this.state.emailInput} /><br />

								<label>Bericht:</label><br />
								<textarea ref={this.state.messageInput} /><br />

								{/* A honeypot field to trick bots. */}
								<input type="text" name="website" id="website" autoComplete="off" ref={this.state.honeypotInput} />

								<input type="submit" className="btn" value="Verzenden" />

								<p id="form-result"></p>
							</form>
						</div>
					</article>
					<article>
						<h3>Contactinformatie</h3>
						<p>PIFinformatie is onderdeel van:</p>
						<br />
						<p>
							Kindermedicijn B.V.<br />
							Bovendijk 163<br />
							3045 PD Rotterdam
						</p>
						<br />
						<p>
							<FontAwesomeIcon icon={faAt} className="contact-info-icon" /> <a href="mailto:info@kindermedicijn.nl">info@kindermedicijn.nl</a><br />
							<FontAwesomeIcon icon={faPhone} className="contact-info-icon" /> +31 (0)10 236 36 60
						</p>
					</article>
					<article>
						<h3>Voor onderzoekers</h3>
						<p>De PIF's die beschikbaar zijn op deze website zijn gemaakt met de PIFmaker van Kindermedicijn B.V.</p>
						<br />
						<p>Voor meer informatie kunt u terecht op <a id="pifmakerlink" href="https://www.pifmaker.nl">www.pifmaker.nl</a>.</p>
					</article>
				</ColumnSection>
				<ColumnSection class="column-1">
				</ColumnSection>
				<ColumnSection class="column-1">
					<div className="disclaimer">
						<hr />
						<p>PIFinformatie is onderdeel van Kindermedicijn B.V.</p>
					</div>
				</ColumnSection>
			</footer>
		);
	}

	// Handles the submission of the contact form.
	handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		var form = this.state.form.current;

		var name = this.state.nameInput.current?.value;
		var email = this.state.emailInput.current?.value;
		var message = this.state.messageInput.current?.value;
		var honeypot = this.state.honeypotInput.current?.value;

		if (!name) {
			form!.querySelector("#form-result")!.innerHTML = "U dient een naam in te vullen.";
			return;
		} else if (!email) {
			form!.querySelector("#form-result")!.innerHTML = "U dient een e-mailadres in te vullen.";
			return;
		} else if (!message) {
			form!.querySelector("#form-result")!.innerHTML = "U dient een bericht in te vullen.";
			return;
		} else if (message.length > 10000) {
			form!.querySelector("#form-result")!.innerHTML = "Sorry, uw bericht is te lang...";
			return;
		}

		// Construct an HTTP request
		var xhr = new XMLHttpRequest();

		if (form != null) {
			xhr.open(form.method, form.action, true);
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			xhr.send("name=" + name + "&email=" + email + "&message=" + message + "&honeypot=" + honeypot);

			// Callback function
			xhr.onloadend = function (e) {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					var data = JSON.parse(xhr.responseText);

					if (data.succeeded === false) {
						form!.querySelector("#form-result")!.innerHTML = "Uw contactformulier kon niet worden verzonden, probeer het later nog eens.";
					}
					else {
						form!.querySelector("#form-result")!.innerHTML = "Uw contactformulier is verzonden, we nemen zo snel mogelijk contact met u op.";
					}
				}
			};
		}
	}
}
