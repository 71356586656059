import * as React from 'react';
import { useState } from 'react';
import { ColumnSection } from '../main/column-sections';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import '../../styling/scss/study-frontpage.scss';
import { useParams } from 'react-router-dom';
import { StudyInterface } from '../../study';
import Navbar from '../header/navbar';
import Image from '../../static/study_frontpage_image.png';
import Thumbnail from '../../static/study_frontpage_video_thumbnail.png';
import Video from '../../static/study_frontpage_video.mp4';

export interface StudyFrontpageParams {
    studyToken: string,
}

export function StudyFrontpage(props: {}) {
    var studyToken: string = (useParams() as StudyFrontpageParams).studyToken;

    const [name, setName] = useState([]);
    const [medicine, setMedicine] = useState([]);

    fetch('/api/pif/' + studyToken + '/', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then((result) => result.json())
        .then(data => {
            setName(((data as StudyInterface).name) as any);
            setMedicine(((data as StudyInterface).medicine) as any);
        });

    return (
        <div className="study-frontpage wrapper">
            <Navbar showNavbarBottom={false} showLangToggle={false} navLinks={[]} showBackground={true} />
            <ColumnSection class="column-2">
                
                <div className="item-1">

                    <h2>Wilt u meedoen aan een wetenschappelijk onderzoek?</h2>

                    <img className="frontpage-image" src={Image} alt="telescoop" />

                    <p>Voor een wetenschappelijk onderzoek zoeken we naar patiënten die hieraan mee willen doen. Voordat
                    u beslist om deel te nemen, is het belangrijk dat u goed geïnformeerd bent. Dit proefpersoneninformatieformulier (PIF) vertelt meer over het onderzoek en wat we van u verwachten.</p>

                    <p>Als u meer wilt weten over wat een medisch-wetenschappelijk onderzoek is, bekijk dan de video hieronder.</p>

                    <video src={Video} poster={Thumbnail} controls={true}></video>

                    <br/>

                    <div className="to-pif-button-container">
                    {
                        <a  href={"/study/" + (useParams() as StudyFrontpageParams).studyToken + "/1/"} id="go-to-next-chapter">
                        <div className="to-pif-button">
                            <span><FontAwesomeIcon icon={faArrowRight} className="to-pif-button-icon"></FontAwesomeIcon></span>
                            <span>Ga naar de PIF</span>
                        </div>
                        </a>
                    }
                    </div>

                </div>
                <div className="item-2"/>
            </ColumnSection>
        </div>
    );
}
