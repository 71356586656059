import * as React from 'react';
import { NavbarTop } from './navbar-items';
import { NavbarBottom } from './navbar-items';

export interface NavbarProps {
    showNavbarBottom: boolean,
    navLinks: Array<any>,
    showLangToggle: boolean,
    showBackground: boolean
}

class Navbar extends React.Component<NavbarProps, {}> {
    render() {
        return (
            <nav className={"navbar " + (this.props.showBackground ? "navbar-background" : "")} id="navbar">
                <div className="container navbar-content">
                    <NavbarTop showLangToggle={this.props.showLangToggle} />
                    {this.props.showNavbarBottom &&
                        <NavbarBottom NavLinks={this.props.navLinks} />
                    }
                </div>
            </nav>
        );
    }
}

export default Navbar;
