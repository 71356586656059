import * as React from 'react';
import '../../styling/scss/main/column-sections.scss';

export interface ColumnSectionProps {
    class: string,
    heading?: string | undefined,
}

export class ColumnSection extends React.Component<ColumnSectionProps, {}> {

    render() {
        return (
            <div className="container">
                {this.props.heading && <h2 className="column-heading">{this.props.heading}</h2>}
                <section className={this.props.class}>
                    {this.props.children}
                </section>
            </div>
        );
    }
}