import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StudyFrontpage } from './components/study/study-frontpage';
import Home from './home';
import Study from './study';

export class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/study/:studyToken/" component={StudyFrontpage} />
                    <Route path="/study/:studyToken/:chapter/:heading/" component={Study} />
                    <Route path="/study/:studyToken/:chapter/" component={Study} />
                </Switch>
            </Router>
        )
    }
}

export default App;