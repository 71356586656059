import React, { ComponentClass } from "react";
import { withRouter } from "react-router";
import { ReactRouterProperties } from './table-of-contents-types'

export interface StudyChapterProps extends ReactRouterProperties {
    pifHTML: string,
    heading: string,
    large: boolean
}

export interface StudyChapterState {

}

class StudyChapter extends React.Component<StudyChapterProps, StudyChapterState> {
    componentDidUpdate() {
        if (this.props.large) {
            this.toggleSize(true);
        }
    }

    showStudy() {
        // Add the whole study to the content element. All chapters are hidden by default.
        document.getElementById("content")!.innerHTML = this.props.pifHTML;

        // Get the current chapter.
        var chapter = document.getElementById('chapter-' + this.props.match?.params.chapter)!;

        // Make the chapter visible.
        chapter.style.display = "block";

        // If the first heading is selected.
        if (chapter.querySelector("h3")?.id === this.props.match?.params.heading) {
            this.showChapterText();
        }
    }

    showChapterText() {
        var chapter = document.getElementById('chapter-' + this.props.match?.params.chapter)!;

        var chapterElements = this.getHeadingWithElements(chapter.firstElementChild?.id);

        // Make all chapter elements visible.
        for (let i = 0; i < chapterElements.length; i++) {
            chapterElements[i]?.style.removeProperty("style");
        }
    }

    // Gets the elements that belong to the heading with the given ID.
    // The list will contain the following elements:
    // - the heading element.
    // - the elements that belong to the heading.
    getHeadingWithElements(id: any): HTMLElement[] {
        let heading = document.getElementById(id);
        let currentElement = heading?.nextElementSibling;

        let elements = [];
        elements.push(heading);

        // Keep adding elements until an h3 element is found.
        while (currentElement != null && currentElement?.tagName.toLowerCase() != "h3") {
            elements.push(currentElement);
            currentElement = currentElement?.nextElementSibling;
        }

        return elements as HTMLElement[];
    }

    toggleSize(large: boolean) {
        // Toggle sizes of text elements.
        let children = (document.getElementsByClassName("study")[0] as HTMLElement).querySelectorAll("[data-text-element], [data-iwt-text-element], [data-enum-element], [data-num-enum-element]");

        for (let i = 0; i < children.length; i++) {
            let currentFontSize = window.getComputedStyle(children[i], null).getPropertyValue("font-size");
            let newFontSize = "calc(" + currentFontSize + (large ? " * 1.25)" : " / 1.25)");

            (children[i] as HTMLElement).style.fontSize = newFontSize;
        }

        // Toggle sizes of images in image elements.
        let imageElements = (document.getElementsByClassName("study")[0] as HTMLElement).getElementsByClassName("image-element");

        for (let i = 0; i < imageElements.length; i++) {
            let imageElement = imageElements[i];

            let currentWidth = window.getComputedStyle(imageElement, null).getPropertyValue("width");
            let newWidth = "calc(" + currentWidth + (large ? " * 1.25)" : " / 1.25)");

            (imageElement as HTMLElement).style.width = newWidth;
        }

        // Toggle sizes of images in image with text elements.
        let imageWithTextElements = (document.getElementsByClassName("study")[0] as HTMLElement).getElementsByClassName("image-with-text-element");

        for (let i = 0; i < imageWithTextElements.length; i++) {
            let iwtTextElement = imageWithTextElements[i].firstElementChild!;

            let currentWidth = window.getComputedStyle(iwtTextElement, null).getPropertyValue("width");
            let newWidth = "calc(" + currentWidth + (large ? " * 1.25)" : " / 1.25)");

            (iwtTextElement as HTMLElement).style.width = newWidth;
        }
    }

    render() {
        return (
            <div>
                <div></div>
                {this.showStudy()}
            </div>
        );
    }
}

export default withRouter(StudyChapter as ComponentClass<any, any>);
