import React from "react";
import "../../styling/scss/main/study-code.scss";

export interface StudyCodeState {
    form: React.RefObject<HTMLFormElement>;
    code: string;
}

export class StudyCode extends React.Component<{}, StudyCodeState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            form: React.createRef(),
            code: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ code: e.target.value });
    }

    handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        var form = this.state.form.current;

        if (this.state.code === "") {
            form!.querySelector(".error-message")!.innerHTML = "U dient een code in te vullen.";

            return;
        }

        // Construct an HTTP request
        var xhr = new XMLHttpRequest();

        if (form != null) {
            xhr.open(form.method, form.action, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.send("code=" + this.state.code);

            // Callback function
            xhr.onloadend = function (e) {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    var data = JSON.parse(xhr.responseText);

                    if (data.succeeded === false) {
                        form!.querySelector(".error-message")!.innerHTML = "Sorry, die code is niet bekend...";
                        (form!.querySelector(".study-code-field")! as HTMLInputElement).value = "";
                    } else {
                        window.location.href = data.url;
                    }
                }
            };
        }
    }

    render() {
        return (
            <div className="study-code-container">
                <div className="card">
                    <h3>Typ uw toegangscode hier in</h3>

                    <form method="POST" action="/api/login/" ref={this.state.form} onSubmit={this.handleSubmit}>
                        <input value={this.state.code} className="study-code-field" type="text" name="code" onChange={this.handleChange} />
                        <br />
                        <div>
                            <input className="btn study-code-button" type="submit" value="Log in" />
                            <p className="error-message"></p>
                        </div>
                    </form>

                    <p>Deze toegangscode staat in de informatiemap die u van uw dokter hebt gekregen.</p>
                </div>
            </div>
        )
    }
}

export default StudyCode;