import * as React from 'react';
import ContactUs from '../main/contact-us';
import StudyCode from '../main/study-code';

export interface HeroSectionProps {
    title: string,
    urlbg: string,
    url: string,
    content: any[]
}

export interface HeroSectionState {

}

class HeroSection extends React.Component<HeroSectionProps, HeroSectionState> {
    render() {
        return (
            <div className="hero-section" style={{ backgroundImage: 'url(' + this.props.urlbg + ')' }}>
                <div className="container hero-content">
                    <div className="column-3">
                        <div className="item-1">
                            <h1>{this.props.title}</h1>
                            <p>Mensen helpen om:</p>
                            <ul>
                                <li>Betere behandelingen voor ziekten te vinden</li>
                                <li>Betere manieren te vinden om een ziekte op te sporen</li>
                                <li>Betere manieren te vinden om een ziekte vast te stellen</li>
                                <li>Meer over een ziekte en over de werking van het lichaam te weten te komen</li>
                            </ul>
                            {this.props.content.map(element => <div>{element}</div>)}
                        </div>
                        <div className="item-2">
                            <StudyCode />
                            <br />
                            <ContactUs />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroSection;
