import React, { ComponentClass } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router";
import { ReactRouterProperties } from '../../components/study/table-of-contents-types';

export interface TextToSpeechProps extends ReactRouterProperties {
  chapter: string
}

export interface TextToSpeechState {
  ttsInitiated: boolean,
  ttsRunning: boolean,
  ttsIcon: IconDefinition,
  chapter: string | undefined
}

class TextToSpeech extends React.Component<TextToSpeechProps, TextToSpeechState> {

  constructor(props: TextToSpeechProps) {
    super(props);
    this.state = {
      // ttsInitiated indicates if tts has started once on the the site.
      ttsInitiated: false,
      // ttsRunning shows if tts is running at the moment
      ttsRunning: false,
      ttsIcon: faVolumeUp,
      chapter: "0"
    }
  }

  componentDidUpdate() {
    // This if statements prevents an infinite loop, because we need to setState when the component chapter changes, but when setState is called componentDidUpdate is called.
    if (this.state.chapter != this.props.match?.params.chapter) {
      this.setState({ chapter: this.props.match?.params.chapter, ttsInitiated: false, ttsRunning: false, ttsIcon: faVolumeUp });

      try {
        (window as any).responsiveVoice.cancel();
      } catch (error) {
        
      }
    }
  }

  // These 3 methods are for the text to speech functionality.
  muteTts() {
    this.setState({ ttsRunning: false, ttsIcon: faVolumeUp });
    (window as any).responsiveVoice.pause();
  }

  unmuteTts() {
    this.setState({ ttsRunning: true, ttsIcon: faVolumeMute });
    (window as any).responsiveVoice.resume();
  }

  initTts() {
    this.setState({ ttsInitiated: true, ttsRunning: true, ttsIcon: faVolumeMute });
    (window as any).responsiveVoice.speak(document.getElementById("content")?.innerText, 'Dutch Male', { rate: 0.85 });
  }

  toggleTts() {
    if (this.state.ttsInitiated === false) {
      this.initTts();
    } else {
      if (this.state.ttsRunning) {
        this.muteTts();
      } else {
        this.unmuteTts();
      }
    }
  }

  render() {
    return (
      <FontAwesomeIcon icon={this.state.ttsIcon} title="Tekst voorlezen" onClick={() => this.toggleTts()} />
    );
  }
}

export default withRouter(TextToSpeech as ComponentClass<any, any>);
