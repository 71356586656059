import React, { ComponentClass } from "react";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { ReactRouterProperties } from '../../components/study/table-of-contents-types';

export interface LanguageDropdownProps extends ReactRouterProperties {
    study: any,
    studyToken: string
}

export interface LanguageDropdownState {
    currentLanguage: string,
    studiesPerLanguage: { languageName: string, studyToken: string } | {}
}

class LanguageDropdown extends React.Component<LanguageDropdownProps, LanguageDropdownState> {
    constructor(props: LanguageDropdownProps) {
        super(props);
        this.state = {
            currentLanguage: "",
            studiesPerLanguage: {}
        }

        fetch("/api/language/" + this.props.studyToken, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((result: Response) => result.json())
            .then(data => {
                this.setState({
                    studiesPerLanguage: data
                });

                var currentToken = Object.values(this.state.studiesPerLanguage).filter(x => x == this.props.studyToken)[0];

                Object.entries(this.state.studiesPerLanguage).forEach(
                    ([key, value]) => {
                        if (value === currentToken) {
                            this.setState({
                                currentLanguage: key
                            })
                        }
                    }
                )
            });
    }

    render() {
        return (
            <div>
                <div className="dropdown">
                    <div><FontAwesomeIcon icon={faGlobeEurope} title="Taal veranderen" /></div>
                    <div className="dropdown-content">
                        {
                            Object.entries(this.state.studiesPerLanguage).map(x => {
                                var name = x[0];
                                var token = x[1];

                                return (
                                    <div className={"dropdown-item " + (name === this.state.currentLanguage ? "current" : "")}>
                                        <a href={"/study/" + token + "/" + this.props.match?.params.chapter + "/"}>{name}</a>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LanguageDropdown as ComponentClass<any, any>);